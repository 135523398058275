import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import SlideVerify from 'vue-monoplasty-slide-verify';
Vue.use(SlideVerify);

import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

import {formatUploadURL} from './utils/index'
Vue.prototype.formatImageUrl = formatUploadURL;

import {
  mapLoader,
  initMap,
  addMarker,
  addText,
  exMarker,
  addErea,
  showDetailInfo,
  search,
  addressPosition,
  communityPosition,
	addMarkerCluster,
	clearWarningMarker,
	parseAddress
} from '@/utils/map.util.js'
Vue.prototype.m_mapLoader = mapLoader
Vue.prototype.m_initMap = initMap
Vue.prototype.m_addMarker = addMarker
Vue.prototype.m_addText = addText
Vue.prototype.m_exMarker = exMarker
Vue.prototype.m_addErea = addErea
Vue.prototype.m_showDetailInfo = showDetailInfo
Vue.prototype.m_search = search
Vue.prototype.m_addressPosition = addressPosition
Vue.prototype.m_communityPosition = communityPosition
Vue.prototype.m_addMarkerCluster = addMarkerCluster
Vue.prototype.m_clearWarningMarker = clearWarningMarker
Vue.prototype.m_parseAddress = parseAddress

import httpRequest from '@/utils/httpRequest' // https://github.com/axios/axios
Vue.prototype.$http = httpRequest // ajax请求方法

import iframeMsg from '@/utils/iframeMsg'
Vue.prototype.$iframeMsg = iframeMsg
Vue.prototype.$topGetToken = (callback) => {
  iframeMsg.sendMsg(window.top, {type: 'getToken'}, function (e, msgData) {
    callback && callback(msgData.token)
  })
}
Vue.prototype.$topLogout = () => {
  iframeMsg.sendMsg(window.top, {type: 'logout'})
}
Vue.prototype.$topGetBigScreenPermissions = (callback) => {
  iframeMsg.sendMsg(window.top, {type: 'getBigScreenPermissions'}, function (e, msgData) {
    callback && callback(msgData.permissions)
  })
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
