import axios from 'axios'
import Vue from 'vue';
import router from '../router'
import iframeMsg from '@/utils/iframeMsg'

// import router from '../router/index'
// const ajax = axios.create({
//   baseURL: 'http://121.37.211.200:8801'
// })
let ajax = axios.create({
    baseURL: window.BASE_URL
})
// console.log(router)
ajax.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
// ajax.defaults.headers["Authorization"] = window.localStorage.getItem('token');
Vue.prototype.$axios = ajax
//请求拦截器
ajax.interceptors.request.use(
    config => {
        if (localStorage.getItem("token")) {
            config.headers['token'] = localStorage.getItem("token");
        }
        return config;
    },
    err => {
        return Promise.reject(err);
    }
);

// 返回状态判断(添加响应拦截器)
ajax.interceptors.response.use(
    res => {
        if(res.data.code == 401) {
            router.push('/login')
            window.localStorage.removeItem('token')
        }
        return res.data;
    },
    err => {
        //Message.error("链接服务器失败！请稍后再试。")
        return Promise.reject(err);
    }
);
export default req => {
    return new Promise(resolve => {
        iframeMsg.sendMsg(window.top, {type: 'getToken'}, function (e, msgData) {
            console.log('大屏获取到token：' + msgData.token)
            localStorage.setItem("token",msgData.token)
            ajax.request({
                url: req.url,
                method: req.method || 'GET',
                params: req.params || {},
                data: req.data || {},
                onUploadProgress: req.progress || (() => {
                    console.log('.....')
                })
            }).then((data) => {
                resolve(data)
            }).catch(e => {
                resolve(e)
            })
        })
    })
}
