const allowHost = [
  // 允许下面的host向当前窗体发送消息
  '127.0.0.1',
  'localhost',
  'oldcare.cdls666.com',
  'h5.oldcare.cdls666.com',
  'big-screen.oldcare.cdls666.com',
  'local.cdls666.com',
	'124.71.40.6'
]

const sendMsgCallback = {}

const msgListeners = {}

const fnLog = function () {
  for (let i = 0; i < arguments.length; i++) {
    if (typeof arguments[i] === 'string') {
      console.log(`[iframeMsg(${location.origin})] ${arguments[i]}`)
    } else {
      console.log(arguments[i])
    }
  }
}

const iframeMsg = {
  // 获取host
  getHost (origin) {
    return typeof origin === 'string'
      ? origin.trim().replace(/^http[s]?[:]\/\//gi, '').replace(/[:]\d+$/gi, '').toLowerCase()
      : ''
  },

  // 获取uuid
  getUUID () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)
    })
  },

  // 发送消息
  // targetWindow 目标窗体
  // msgData 发送的消息,格式 {id:'消息id,留空则自动生成', type:'消息类型', ...其他参数}
  // callback 回调函数
  sendMsg (targetWindow, msgData, callback) {
    msgData = msgData || {}
    if (!msgData.id) {
      msgData.id = iframeMsg.getUUID()
    }
    if (!msgData.type) {
      throw new Error('消息type不能为空')
    }

    // 设置回调
    if (typeof callback === 'function') {
      sendMsgCallback[msgData.id] = callback
    }

    // 发送
    targetWindow.postMessage(msgData, '*')
  },

  // 回复消息
  replyMsg (e, replyData) {
    if (e.data && e.data.id) {
      e.source.postMessage({
        id: iframeMsg.getUUID(),
        sourceMsgId: e.data.id,
        ...replyData
      }, e.origin)
    }
  },

  // 订阅消息
  on (type, fn) {
    if (type && typeof fn === 'function') {
      if (!Array.isArray(msgListeners[type])) {
        msgListeners[type] = []
      }
      if (!msgListeners[type].find(ele => ele === fn)) {
        msgListeners[type].push(fn)
      }
    }
    return iframeMsg
  },

  // 取消订阅消息
  off (type) {
    if (Array.isArray(msgListeners[type])) {
      if (arguments.length === 1) {
        msgListeners[type] = []
      } else if (arguments.length > 1) {
        let findIdx = msgListeners[type].findIndex(ele => ele === arguments[1])
        if (findIdx !== -1) {
          msgListeners[type].splice(findIdx, 1)
        }
      }
    }
    return iframeMsg
  }
}

// 监听消息
window.addEventListener('message', e => {
  // 验证消息格式
  const data = e.data
  if (!data.id) {
    // 消息格式不正确,忽略
    return
  }

  // 验证host
  const host = iframeMsg.getHost(e.origin)
  if (!allowHost.includes(host)) {
    fnLog(`消息来自${e.origin},host不允许,忽略`)
    return
  }

  fnLog(`处理来自${e.origin}的消息`, e)

  // 订阅处理
  if (data.type && Array.isArray(msgListeners[data.type])) {
    msgListeners[data.type].forEach(fn => {
      fn(e, data)
    })
    fnLog(`订阅处理完成`)
  }

  // 回调处理
  if (data.sourceMsgId && typeof sendMsgCallback[data.sourceMsgId] === 'function') {
    sendMsgCallback[data.sourceMsgId](e, data)
    fnLog(`回调处理完成`)
  }
}, false)

export default iframeMsg
