import Vue from "vue";
import VueRouter from "vue-router"
Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        redirect:'/index' ,
        // redirect: localStorage.getItem('token') ? '/index' : '/login',
        // component: () => import('../pages/login'),
    },
    {
        path: '/login',
        // redirect: '/home',
        name: 'login',
        component: () => import('../pages/login'),
    },
    {
        path: '/index',
        // redirect: '/home',
        name: 'Index',
        component: () => import('../pages/index'),
    },
    {
        path: '/visit',
        name: 'visit',
        component: () => import('../pages/visit'),
    },
    {
        path: '/meal',
        name: 'meal',
        component: () => import('../pages/meal'),
    },
    {
        path: '/oldman',
        name: 'oldman',
        component: () => import('../pages/oldman'),
    },
]
const router = new VueRouter({
    routes
})
//防止重复点击
let pushPlugin = VueRouter.prototype.push

VueRouter.prototype.push = function(config) {
    return pushPlugin.call(this, config).catch(() => {
        console.log('点击了重复路径')
    })
}
//路由拦截
router.beforeEach((to, from, next) => {
    // to and from are both route objects. must call `next`.
    // next()
    // if(localStorage.getItem("token")) {
    //     // router.push({name: 'index'})
    //     next()
    // }else {
    //     router.push({name: 'login'})
    // }
    next()
})
export default router