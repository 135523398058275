const state = {
    userInfo: {},
    token: '',
    typeList: [],
    remarkStatus: false,
}

const getters = {
    getUserInfo: state => state.userInfo,
    getToken: state => state.token,
    getTypeList: state => state.typeList,
    getRemarkStatus: state => state.remarkStatus
}

const actions = {
    setUserInfo({commit}, user) {
        commit('mutationUserInfo', user)
    },
    setToken({commit}, token) {
        commit('mutationToken', token)
    },
    setTypeList({commit}, list) {
        commit('mutationTypeList', list)
    },
    setRemarkStatus({commit},remarkStatus) {
        commit('mutationRemarkStatus', remarkStatus)
    }
}

const mutations = {
    mutationUserInfo(state, user) {
        state.userInfo = user
    },
    mutationToken(state, token) {
        state.token = token
    },
    mutationTypeList(state, list) {
        state.typeList = list
    },
    mutationRemarkStatus(state, remarkStatus) {
        state.remarkStatus = remarkStatus
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}