import Vue from 'vue'
import router from '@/router'
import store from '@/store'
import {Message, Notification} from 'element-ui'

/**
 * 获取uuid
 */
export function getUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)
    })
}

/**
 * 是否有权限
 * @param {*} key
 */
export function isAuth(key) {
    if (sessionStorage.getItem('is_super_admin') == 1) return true; // 超级管理员
    return JSON.parse(sessionStorage.getItem('permissions') || '[]').indexOf(key) !== -1 || false
}

/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDataTranslate(data, id = 'id', pid = 'parentId') {
    var res = []
    var temp = {}
    for (var i = 0; i < data.length; i++) {
        temp[data[i][id]] = data[i]
    }
    for (var k = 0; k < data.length; k++) {
        if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
            if (!temp[data[k][pid]]['children']) {
                temp[data[k][pid]]['children'] = []
            }
            if (!temp[data[k][pid]]['_level']) {
                temp[data[k][pid]]['_level'] = 1
            }
            data[k]['_level'] = temp[data[k][pid]]._level + 1
            temp[data[k][pid]]['children'].push(data[k])
        } else {
            res.push(data[k])
        }
    }
    return res
}

/**
 * 清除登录信息
 */
export function clearLoginInfo() {
    Vue.cookie.delete('token')
    store.commit('resetStore')
    router.options.isAddDynamicMenuRoutes = false
}

/**
 * 上传地址转绝对地址
 */
export function formatUploadURL(relUrl/*相对或绝对地址*/) {
    relUrl = relUrl || ''
    if (relUrl.startsWith('http://') || relUrl.startsWith('https://') || relUrl.startsWith('//')) {
        return relUrl
    } else {
        return window.BASE_URL + relUrl
    }
}

/**
 * 获取文件下载地址
 */
export function getFileDownUrl(relUrl/*相对或绝对地址*/) {
    let url = formatUploadURL(relUrl || '')
    url += url.indexOf('?') >= 0 ? '&' : '?'
    url += 'downfile=1'
    return url
}

/**
 * tree工具
 */
let treeUtil = {
    _check_tree(tree) {
        return tree && tree.length ? tree : []
    },
    getParent(tree, currId, keyName = 'id') {
        tree = this._check_tree(tree)

        let fnFind = (list, listParent = null) => {
            for (var i = 0; i < list.length; i++) {
                if (list[i][keyName] == currId) {
                    return listParent
                }
                if (list[i].children && list[i].children.length) {
                    let childRes = fnFind(list[i].children, list[i])
                    if (childRes) {
                        return childRes
                    }
                }
            }
            return null
        }
        return fnFind(tree)
    },
    getParentFilter(tree, currId, keyName = 'id', fnFilter) {
        let fnFind = (findCurrId) => {
            let parent = treeUtil.getParent(tree, findCurrId, keyName)
            if (!parent) {
                return null
            } else {
                if (fnFilter(parent)) {
                    return parent
                } else {
                    return fnFind(parent[keyName])
                }
            }
        }
        return fnFind(currId)
    },
    getFirstChild(tree, currId, keyName = 'id') {
        tree = this._check_tree(tree)

        let fnFind = (list, listParent = null) => {
            for (var i = 0; i < list.length; i++) {
                if (list[i][keyName] == currId) {
                    if (list[i].children && list[i].children.length) {
                        return list[i].children[0]
                    }
                }
                if (list[i].children && list[i].children.length) {
                    let childRes = fnFind(list[i].children, list[i])
                    if (childRes) {
                        return childRes
                    }
                }
            }
            return null
        }
        return fnFind(tree)
    },
    getLastChild(tree, currId, keyName = 'id') {
        tree = this._check_tree(tree)

        let fnFind = (list, listParent = null) => {
            for (var i = 0; i < list.length; i++) {
                if (list[i][keyName] == currId) {
                    if (list[i].children && list[i].children.length) {
                        return list[i].children[list[i].children.length - 1]
                    }
                }
                if (list[i].children && list[i].children.length) {
                    let childRes = fnFind(list[i].children, list[i])
                    if (childRes) {
                        return childRes
                    }
                }
            }
            return null
        }
        return fnFind(tree)
    },
    getPrev(tree, currId, keyName = 'id') {
        tree = this._check_tree(tree)

        let fnFind = (list, listParent = null) => {
            for (var i = 0; i < list.length; i++) {
                if (list[i][keyName] == currId) {
                    return i > 0 ? list[i - 1] : null
                }
                if (list[i].children && list[i].children.length) {
                    let childRes = fnFind(list[i].children, list[i])
                    if (childRes) {
                        return childRes
                    }
                }
            }
            return null
        }
        return fnFind(tree)
    },
    getNext(tree, currId, keyName = 'id') {
        tree = this._check_tree(tree)

        let fnFind = (list, listParent = null) => {
            for (var i = 0; i < list.length; i++) {
                if (list[i][keyName] == currId) {
                    return i < (list.length - 1) ? list[i + 1] : null
                }
                if (list[i].children && list[i].children.length) {
                    let childRes = fnFind(list[i].children, list[i])
                    if (childRes) {
                        return childRes
                    }
                }
            }
            return null
        }
        return fnFind(tree)
    },
    getCurrFirst(tree, currId, keyName = 'id') {
        tree = this._check_tree(tree)

        let fnFind = (list, listParent = null) => {
            for (var i = 0; i < list.length; i++) {
                if (list[i][keyName] == currId) {
                    return list[0]
                }
                if (list[i].children && list[i].children.length) {
                    let childRes = fnFind(list[i].children, list[i])
                    if (childRes) {
                        return childRes
                    }
                }
            }
            return null
        }
        return fnFind(tree)
    },
    getCurrLast(tree, currId, keyName = 'id') {
        tree = this._check_tree(tree)

        let fnFind = (list, listParent = null) => {
            for (var i = 0; i < list.length; i++) {
                if (list[i][keyName] == currId) {
                    return list[list.length - 1]
                }
                if (list[i].children && list[i].children.length) {
                    let childRes = fnFind(list[i].children, list[i])
                    if (childRes) {
                        return childRes
                    }
                }
            }
            return null
        }
        return fnFind(tree)
    },
    getById(tree, currId, keyName = 'id') {
        tree = this._check_tree(tree)

        let fnFind = (list, listParent = null) => {
            for (var i = 0; i < list.length; i++) {
                if (list[i][keyName] == currId) {
                    return list[i]
                }
                if (list[i].children && list[i].children.length) {
                    let childRes = fnFind(list[i].children, list[i])
                    if (childRes) {
                        return childRes
                    }
                }
            }
            return null
        }
        return fnFind(tree)
    },
    visitTree(tree, cb) {
        tree = this._check_tree(tree)

        let fnFind = (list, listParent = null) => {
            for (var i = 0; i < list.length; i++) {
                cb && cb(list[i], i, list, listParent, tree)
                if (Array.isArray(list[i].children)) {
                    fnFind(list[i].children, list[i])
                }
            }
            return null
        }
        fnFind(tree)
    }
}
export {treeUtil}

/**
 * 格式化文件大小
 */
export function formatFileSize(fileSize, idx = 0) {
    fileSize = fileSize > 0 ? fileSize : 0
    const units = ["B", "KB", "MB", "GB"];
    if (fileSize < 1024 || idx === units.length - 1) {
        return (
            Number.isInteger(fileSize) ? fileSize : fileSize.toFixed(1)
        ) + units[idx];
    }
    return formatFileSize(fileSize / 1024, ++idx);
}

let formatUtils = {
    formatDistance(val/*米*/) {
        let v = val > 0 ? val : 0;
        return Math.ceil((v / 1000) * 100) / 100 + 'km'
    },
    formatTime(val/*秒*/) {
        let v = val > 0 ? val : 0;

        let sesc = v % 60;
        let mins = Math.floor(v / 60) % 60
        let hour = Math.floor(v / 3600)

        return (hour < 10 ? '0' : '') + hour + ':'
            + (mins < 10 ? '0' : '') + mins + ':'
            + (sesc < 10 ? '0' : '') + sesc;
    },
    formatTravelType(val) {
        if (val == '1') return '实时订单';
        else if (val == '2') return '预约订单';
        else if (val == '4') return '跑腿订单';
        else if (val == '5') return '接送机订单';
        else return '其他';
    },
    formatTravelStatus(val) {
        if (val == '1') return '等待接单';
        else if (val == '2') return '司机接单';
        else if (val == '3') return '司机到达';
        else if (val == '4') return '乘客/物品上车';
        else if (val == '5') return '已完成';
        else if (val == '6') return '已支付';
        else if (val == '9') return '已关闭';
        else return '其他';
    },
    formatPayType(val) {
        if (val == '1') return '支付宝支付';
        else if (val == '2') return '微信支付';
        else if (val == '3') return '银联支付';
        else if (val == '4') return '余额支付';
    }
}
export {formatUtils}

/**
 * UEditor配置
 */
export function ueditorConfig(config) {
    var cdnUrl = window.SITE_CONFIG['cdnUrl'];
    if (!cdnUrl.endsWith('/')) cdnUrl += '/'

    var ueUrl = cdnUrl + 'static/plugins/ueditor-1.4.3.3/'

    console.log('[UEditor资源路径]' + ueUrl)

    return Object.assign({
        UEDITOR_HOME_URL: ueUrl,
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 初始容器高度
        initialFrameHeight: 500,
        // 初始容器宽度
        initialFrameWidth: '100%',
        // 上传文件接口
        serverUrl: window.BASE_URL + '/common/ueditor',
    }, config)
}

/**
 * 解析附件类型
 */
export function parseList(images, type) {
  let imageList = JSON.parse(images)
  let list = []
  if (type && type == 'image' && images && images.length) {
    imageList.forEach(item => {
      if ((/(\.jpg|\.jpeg|\.png|\.bmp)(\?.*)?$/gi.test(item.raw))) {
        list.push(item)
      }
    })
  } else if (type && type == 'video' && images && images.length) {
    imageList.forEach(item => {
      if ((/\.mp4(\?.*)?$/gi.test(item.raw))) {
        list.push(item)
      }
    })
  } else {
    if (images && images.length) {
      imageList.forEach(item => {
        if (!(/(\.jpg|\.jpeg|\.png|\.bmp)(\?.*)?$/gi.test(item.raw)) && !(/\.mp4(\?.*)?$/gi.test(item.raw))) {
          list.push(item)
        }
      })
    }
  }
  return list
}
