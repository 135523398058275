import Ajax from './ajax'

const login = (data) => {
    return Ajax({
        url: '/sys/login',
        method: 'POST',
        data
    })
}
const setupReport = () => {
    return Ajax({
        url: '/device/big-screen/setup-report'
    })
}

const onlineReport = () => {
    return Ajax({
        url: '/device/big-screen/online-report',
    })
}

const alarmReport = (type) => {
    return Ajax({
        url: '/device/big-screen/alarm-report',
				params: {
					reportType: type
				}
    })
}

const todayEvent = () => {
    return Ajax({
        url: '/device/big-screen/today-event'
    })
}

const eventTypeReport = () => {
    return Ajax({
        url: '/device/big-screen/event-type-report'
    })
}

const eventList = (data) => {
    return Ajax({
        url: '/device/big-screen/event-list',
				data: data,
				method: 'POST'
    })
}

const personList = (data) => {
    return Ajax({
        url: '/device/big-screen/person-list',
				data: data,
				method: 'POST'
    })
}

const personEventList = (data) => {
    return Ajax({
        url: '/device/big-screen/person-event-list',
				data: data,
				method: 'POST'
    })
}

const personDeviceList = (mac) => {
    return Ajax({
        url: '/device/big-screen/person-device-list',
				params: {mac},
    })
}

const processEvent = (params) => {
    return Ajax({
        url: '/device/deviceeventalarm/process',
				params: params,
    })
}

export {
    login,
    setupReport,
    onlineReport,
    alarmReport,
    todayEvent,
    eventTypeReport,
    eventList,
		personList,
		personEventList,
		personDeviceList,
		processEvent
}