<template>
  <div ref="myChart" class="box" :style="{'width': '100%', 'height': boxHeight ? boxHeight + 'vh' : '100%'}"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  props: {
    // y轴显示的名字
    yName: {
      type: String,
      required: false,
    },
    // x轴显示的名字
    xName: {
      type: String,
      required: false,
    },
    // x轴文本集合
    xData: {
      type: Array,
      required: false,
    },
    // echarts高度
    boxHeight: {
      type: Number,
      required: false,
      default: 0,
    },
    // 全局type
    type: {
      type: String,
      required: false,
      default: "line",
    },
    // 圆环，南丁格尔
    pieRadius: {
      type: Array,
      required: false,
    },
    // 重叠（bar）
    overlay: {
      type: Boolean,
      default: false,
    },
    // series
    // line/bar: [{type: bar, name: '', data: []}]
    // pie: [{name: '', value: 12}]
    series: {
      type: Array,
      required: false,
    },
    // 简单模式，不在图形上显示文本
    simple: {
      type: Boolean,
      default: false,
    },
    // 颜色集合，依次显示
    color: {
      type: Array,
      default: () => {
        return new Array(
          "#1363EB",
          "#FFC837",
          "#5A64F4",
          "#20CCF8",
          "#F0882B",
          "#15CBFC"
        );
      },
    },
    // 横向
    cross: {
      type: Boolean,
      default: false,
    },
    // 面积图
    area: {
      type: Boolean,
      default: false,
    },
    // 圆角柱状
    circle: {
      type: Boolean,
      default: false,
    },
    // 距离左边距离
    gridLeft: {
      type: Number,
      default: 30,
    },
    gridTop: {
      type: Number,
      default: 30,
    },
    gridBottom: {
      type: Number,
      default: 40,
    },
    // y轴最大值
    maxY: {
      type: Number,
      default: 4,
    },
    // 饼状图位置
    pieCenter: {
      type: Array,
      required: false,
    },
    legendShow: {
      type: Boolean,
      default: false,
    },
    pieCenterShow: {
      type: Boolean,
      default: false,
    },
    seriesItemStyle: {
      type: Object,
      default: () => {
        return {}
      },
    },
    // 饼状图中心文字
    pieCenterText: {
      type: String,
      required: false,
      default: "",
    },
  },
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {
    data: function () {},
    series: function () {
      this.show();
    },
    deep: true,
    immediate: true,
    "$route.path": {
      handler(newValue, oldValue) {
        let myChart = echarts.getInstanceByDom(this.$refs.myChart);
        myChart.resize();
      },
      deep: true,
    },
    boxHeight: {
      handler(val) {
        let myChart = echarts.getInstanceByDom(this.$refs.myChart);
        myChart.resize();
      },
      deep: true,
    },
  },
  created() {
    // echarts 宽度 自适应
  },
  mounted() {
    this.show();
  },
  methods: {
    show() {
      if (!this.series || !this.series.length) {
        return;
      }
      let yAxis = [
        {
          type: "value",
          name: this.yName,
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(14, 134, 255, .3)",
            },
          },
          // max: this.maxY > 4 ? this.maxY : 4 ,
        },
      ];

      let xAxis = [
        {
          type: "category",
          axisTick: {
            show: false,
          },
          axisLine:
            this.type == "pie" || this.type == "rosePie"
              ? undefined
              : {
                  lineStyle: {
                    color: "rgba(14, 134, 255, .3)",
                  },
                },
          axisLabel: {
            show: true,
            interval: 0,
            textStyle: {
              color: "#ccc", //文字颜色
              fontSize: 10,
            },
            lineHeight: 17,
          },
          boundaryGap: false,
          data: this.xData,
        },
      ];

      let seriesList = [];
      // 饼图
      if (this.type == "pie" || this.type == "rosePie") {
        seriesList.push({
          hoverAnimation: true, //鼠标悬浮是否有区域弹出动画，false:无  true:有
          name: "",
          type: "pie",
          radius: this.pieRadius ? this.pieRadius : ["75%"], //设置饼图大小宽度
          center: this.pieCenter ? this.pieCenter : ["50%", "50%"],
          avoidLabelOverlap: false,
          roseType: this.type == "rosePie" ? "area" : undefined,
          itemStyle: this.seriesItemStyle,
          label: {
            show: this.simple ? false : true,
            // 这里定义了文本 百分比 是'value'样式的
            formatter: "{title|{b}}\n {value|{d}%}",
            rich: {
              // 这个'value'样式表示文字颜色为白色
              title: {
                color: "#f1f1f1",
              },
              value: {
                color: "#fff",
              },
            },
          },
          emphasis: {
            label: {
              show: this.simple ? false : true,
              fontSize: "12",
              fontWeight: "bold",
            },
          },
          labelLine: {
            show: this.simple ? false : true,
            lineStyle: {
              color: "rgba(255, 255, 255, 0.3)",
            },
            smooth: 0.2,
            length: 5,
            length2: 5,
          },
          data: this.series,
        });
      } else {
        this.series.forEach((item, idx) => {
          seriesList.push({
            type: item.type ? item.type : this.type,
            name: item.name,
            data: item.data,
            stack: this.overlay && item.type == "bar" ? this.yName : idx,
            barMaxWidth: 20,
            areaStyle: !this.area ? undefined : {},
            itemStyle: this.circle ? { borderRadius: 15 } : undefined,
            smooth: true,
            showSymbol: false,
          });
        });
        // 横向
        if (this.cross) {
          let tmp = xAxis;
          xAxis = yAxis;
          yAxis = tmp;
        }
      }
      let dataFilter = this.series;
      let type = this.type
      let option = {
        color: this.color,
        // title: {
        //   show: this.legendShow && this.type != 'line',
        //   text: "50/人",
        //   left: "20%",
        //   top: "center",
        //   textStyle: {
        //     color: "#fff",
        //   },
        // },
        grid: {
          show: false,
          borderColor: "rgba(14, 134, 255, .3)",
          top: this.gridTop
            ? this.gridTop
            : seriesList && seriesList.length > 1
            ? 60
            : 30,
          right: 30,
          left: this.gridLeft,
          bottom: this.gridBottom,
          textStyle: {
            color: "#fff",
          },
        },
        tooltip: {
          trigger: "item",
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        graphic: {
          elements: this.pieCenterShow ? [
            {
              type: 'text',
              left:'0',
              top:'45%',
              2: 2,
              zlevel: 100,
              style: {
                  text: this.pieCenterText,
                  font: '18px Microsoft YaHei',
                  fill:'#fff',
                  width:'200',
                  textAlign: 'center',
                  overflow:'truncate',
              }
            },
          ] : [],
        },
        legend: {
          show: this.legendShow ? true : false,
          // show: true,
          type: "plain",
          left: this.type == 'line' ? '0%' : "58%",
          // right: "0%" ,
          top: this.type == 'line' ? '0%' : "center",
          width: this.type == 'line' ? '70%' : "20%",
          icon: "roundRect",
          textStyle: {
            color: "#ccc",
            fontSize: 10,
          },
          formatter: function (name) {
            if (type == "pie") {
              let pieLegendVale = {};
              dataFilter.filter((item, index) => {
                if (item.name == name) {
                  pieLegendVale = item;
                }
              });
              if (pieLegendVale.name.length == 5) {
                return (
                  pieLegendVale.name +
                  "        " +
                  pieLegendVale.value +
                  "     " +
                  pieLegendVale.percent
                );
              } else {
                return (
                  pieLegendVale.name +
                  "            " +
                  pieLegendVale.value +
                  "     " +
                  pieLegendVale.percent
                );
              }
            } else {
              return name;
            }
          },
          padding: [13, 20, 9, 10],
          itemGap: 14,
          itemWidth: 10,
          itemHeight: 10,
        },
        calculable: true,
        xAxis: xAxis,
        yAxis: yAxis,
        series: seriesList,
      };
      window.addEventListener("resize", () => {
        myChart.resize();
      });
      let myChart = echarts.getInstanceByDom(this.$refs.myChart);
      if (myChart == null) {
        myChart = echarts.init(this.$refs.myChart);
      }
      myChart.setOption(option);
      window.onresize = () => {
        myChart.resize();
      };
    },
  },
};
</script>

<style scoped lang="less">
</style>
