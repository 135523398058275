import polylineChart from "../components/polyline-chart.vue";
import {
    communityInfo,
    submitStatus,
    communityWarning,
    communityList,
} from '../api/indexApi'
import store from '../store'

let warningMarkerList = []

function mapLoader() {
    return new Promise((resolve, reject) => {
        if (window.AMap) {
            resolve(window.AMap)
        } else {
            var script = document.createElement('script')
            script.type = 'text/javascript'
            script.async = false
            script.src = 'https://webapi.amap.com/maps?v=2.0&key=cfc6b825df8e547b3da8702470db56ef' +
                '&plugin=AMap.Driving' +
                ',AMap.TileLayer' +
                ',Map3D' +
                ',AMap.DistrictSearch' +
                ',AMap.PolyEditor' +
                ',AMap.PlaceSearch' +
                ',AMap.InfoWindow' +
                ',AMap.GraspRoad' +
                ',AMap.DistrictSearch' +
                ',AMap.DistrictLayer' +
                ',AMap.Polyline' +
                ',AMap.Geocoder' +
                ',AMap.Autocomplete' +
                ',AMap.PlaceSearch' +
                ',AMap.GeoJSON' +
                ',AMap.MarkerCluster' +
                '&callback=initAMap'
            script.onerror = reject
            document.head.appendChild(script)
        }
        window.initAMap = () => {
            resolve(window.AMap)
        }
    })
}

var placeSearch;

function initMap(mapStyle, center, zoom, mapOptions) {
	if (!mapStyle) {
		mapStyle = "amap://styles/fb6c7da239ff5fa44ec20aea263a53cf"
	}
    let map = new AMap.Map('allmap', Object.assign({
      center: center ? center : [104.566034, 30.402503],
      resizeEnable: true,
      mapStyle: mapStyle,
      zoom: zoom ? zoom : 11,
      labelzIndex: 130,
      viewMode: '2D'
    }, mapOptions || {}));
    // var object3Dlayer = new AMap.Object3DLayer({
    //   zIndex: 1
    // });
    var opts = {
      subdistrict: 0,
      extensions: 'all',
      level: 'city'
    };
    //利用行政区查询获取路径
    var district = new AMap.DistrictSearch(opts);
    district.search('简阳市', (status, result) => {
      var bounds = result.districtList[0].boundaries;
      // rgba
      var color = '#27B0DD';
      for (var i = 0; i < bounds.length; i += 1) {
					// var line = new AMap.Object3D.MeshLine({
					//   path: bounds[i],
					//   color: color,
					//   width: 2
					// });
        // object3Dlayer.add(line)
      }
      // map.add(object3Dlayer);
    
    });
    // 添加搜索功能
    // AMap.service(["AMap.PlaceSearch"], function() {
    //   //构造地点查询类
    //   placeSearch = new AMap.PlaceSearch({
    //     pageSize: 5, // 单页显示结果条数
    //     pageIndex: 1, // 页码
    //     // city: "0832", // 兴趣点城市
    //     // citylimit: true, //是否强制限制在设置的城市内搜索
    //     map: map, // 展现结果的地图实例
    //     panel: "panel", // 结果列表将在此容器中进行展示。
    //     autoFitView: true // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
    //   });
    //   //关键字查询
    //   // placeSearch.search('汽车站');
    // });
    return map;
}

function search(key) {
    if (placeSearch) {
        //关键字查询
        key = '简阳市' + key;
        placeSearch.search(key);
    }
}

function initMap3D(mapStyle, center, zoom, callback) {
    var opts = {
        subdistrict: 0,
        extensions: 'all',
        level: 'city'
    };
    let map = null;
    //利用行政区查询获取路径
    var district = new AMap.DistrictSearch(opts);
    district.search('简阳市', (status, result) => {
        var bounds = result.districtList[7].boundaries;
        var mask = [];
        for (var i = 0; i < bounds.length; i += 1) {
            mask.push([bounds[i]]);
        }
        map = new AMap.Map("allmap", {
            mask: mask,
            center: center ? center : [26.613322, 101.55477],
            // disableSocket: true,
            viewMode: "3D",
            features: ["bg", 'point'],
            mapStyle: mapStyle,
            // showLabel: false,
            labelzIndex: 130,
            top: 0,
            pitch: 30, //3D下有效果
            resizeEnable: true,
            rotateEnable: true,
            zoom: zoom ? zoom : 12,
            // layers: [googleLayer],
        });
        // // 设置光照
        map.AmbientLight = new AMap.Lights.AmbientLight([1, 1, 1], 0.5);
        map.DirectionLight = new AMap.Lights.DirectionLight([0, 0, 1], [1, 1, 1], 1);

        var object3Dlayer = new AMap.Object3DLayer();
        map.add(object3Dlayer);

        var color = '#0088ffcc';
        for (var i = 0; i < bounds.length; i += 1) {
            var wall = new AMap.Object3D.Wall({
                path: bounds[i],
                color: color,
                width: 5,
                height: -5000
            });
            wall.backOrFront = 'both';
            wall.transparent = true;
            object3Dlayer.add(wall)

            var line = new AMap.Object3D.MeshLine({
                path: bounds[i],
                color: color,
                width: 5
            });
            object3Dlayer.add(line)
        }
        map.add(object3Dlayer);
        if (callback) {
            callback(map);
        }

    });

}

/**
 * map 地图
 * element marker业务数据
 * ani 是否跳动
 * showDetail 点击是否显示详情
 * callback 点击的回调事件
 */
function addMarker(map, element,callback = null, ani = false, showDetail = true) {
    let offset = new AMap.Pixel(-128, -55)
    if (ani) {
        offset = new AMap.Pixel(-109, -130)
    }
    var icon = new AMap.Icon({
        size: new AMap.Size(128, 55),
        // image: icon,
        image: element.num ? element.num == -1 ? require("../image/oldman/remark.png") : require("../image/icon_yujing.png") : require("../image/icon.png"),
        imageSize: new AMap.Size(65,55)
    });
    // var content = `<div class="content-icon" style="height:40px;width:30px;filter:url(#${index})"><img style="height:40px;width:30px" src="${icon}"></div>`
    var marker = new AMap.Marker({
        position: element.position,
        // content: content,
        // 将一张图片的地址设置为 icon
        icon: icon, //'//a.amap.com/jsapi_demos/static/demo-center/icons/dir-via-marker.png',
        // 设置了 icon 以后，设置 icon 的偏移量，以 icon 的 [center bottom] 为原点
        offset: offset,
				zIndex: element.num == 1 ? 101 : 100
    });
		marker.setExtData(element.data)
    
    map.add(marker);
    if(element.num && element.num != -1) {
        marker.setLabel({
            direction:'center',
            offset: new AMap.Pixel(30, -40),  //设置文本标注偏移量
            content: `<div class='info-warning'><span>${element.name}</span><span>(${element.num})</span></div>`, //设置文本标注内容
        });
		warningMarkerList.push(marker)
    }else {
        marker.setLabel({
            direction:'center',
            offset: new AMap.Pixel(30, -40),  //设置文本标注偏移量
            content: `<div class='info'>${element.name}</div>`, //设置文本标注内容
        });
    }
    
    if (ani) {
        marker.setAnimation("AMAP_ANIMATION_BOUNCE");
    }
    let type = 'ordinary'
    marker.on("click", (e) => {
        if (showDetail) {
            // showMarkerInfo(map, element, e, ani);
        }
        if (callback) {
            callback(element.data);
        }
        if(element.nameType === 'garbage'){
            showMarkerInfo(map, element, e, ani);
        }
        if(!element.num) {
            map.setFitView(marker);
        }
        
    });
    return marker;
}

function addMarkerCluster(map, points, callback) {
	 var gridSize = 60
	    // 数据中需包含经纬度信息字段 lnglat
	    // var points = [
	        // { lnglat: ["108.939621", "34.343147"] },
	        // { lnglat: ["112.985037", "23.15046"] },
	        // ...
	        // ...
	    // ]
			let cluster = null
	    var count = points.length;
	    var _renderClusterMarker = function (context) {
	        var factor = Math.pow(context.count / count, 1 / 18);
	        var div = document.createElement('div');
	        var Hue = 180 - factor * 180;
	        var bgColor = 'hsla(' + Hue + ',100%,40%,0.7)';
	        var fontColor = 'hsla(' + Hue + ',100%,90%,1)';
	        var borderColor = 'hsla(' + Hue + ',100%,40%,1)';
	        var shadowColor = 'hsla(' + Hue + ',100%,90%,1)';
	        div.style.backgroundColor = bgColor;
	        var size = Math.round(30 + Math.pow(context.count / count, 1 / 5) * 20);
	        div.style.width = div.style.height = size + 'px';
	        div.style.border = 'solid 1px ' + borderColor;
	        div.style.borderRadius = size / 2 + 'px';
	        div.style.boxShadow = '0 0 5px ' + shadowColor;
	        div.innerHTML = context.count;
	        div.style.lineHeight = size + 'px';
	        div.style.color = fontColor;
	        div.style.fontSize = '14px';
	        div.style.textAlign = 'center';
	        context.marker.setOffset(new AMap.Pixel(-size / 2, -size / 2));
	        context.marker.setContent(div)
	    };
	    var _renderMarker = function(context) {
	        // var content = '<div style="background-color: hsla(180, 100%, 50%, 0.3); height: 18px; width: 18px; border: 1px solid hsl(180, 100%, 40%); border-radius: 12px; box-shadow: hsl(180, 100%, 50%) 0px 0px 3px;"></div>';
	        				
					var icon = new AMap.Icon({
					    size: new AMap.Size(128, 55),
					    image: require("../image/icon.png"),
					    imageSize: new AMap.Size(65, 55)
					});
					
	        context.marker.setOffset(new AMap.Pixel(-128, -55))
	        context.marker.setIcon(icon)
					
					context.marker.setLabel({
					    direction:'center',
					    offset: new AMap.Pixel(30, -40),  //设置文本标注偏移量
					    content: `<div class='info'>${context.data[0].data.personIdText ? context.data[0].data.personIdText : context.data[0].data.name}</div>`, //设置文本标注内容
					});
					
					context.marker.on('click', (e) => {
						// console.log('e', e)
						// console.log('context.data[0].data', context.data[0].data)
						if (callback) {
							callback(context.data[0].data)
						}
					})
	    }
	    
	    addCluster(2);
	    
	    function addCluster(tag) {
	        if (cluster) {
	            cluster.setMap(null);
	        }
	        if (tag == 2) {//完全自定义
	            cluster = new AMap.MarkerCluster(map, points, {
	                gridSize: gridSize, // 设置网格像素大小
	                renderClusterMarker: _renderClusterMarker, // 自定义聚合点样式
	                renderMarker: _renderMarker, // 自定义非聚合点样式
	            });
	        } else if (tag == 1) {//自定义图标
	            var sts = [{
	                url: "//a.amap.com/jsapi_demos/static/images/blue.png",
	                size: new AMap.Size(32, 32),
	                offset: new AMap.Pixel(-16, -16)
	            }, {
	                url: "//a.amap.com/jsapi_demos/static/images/green.png",
	                size: new AMap.Size(32, 32),
	                offset: new AMap.Pixel(-16, -16)
	            }, {
	                url: "//a.amap.com/jsapi_demos/static/images/orange.png",
	                size: new AMap.Size(36, 36),
	                offset: new AMap.Pixel(-18, -18)
	            }, {
	                url: "//a.amap.com/jsapi_demos/static/images/red.png",
	                size: new AMap.Size(48, 48),
	                offset: new AMap.Pixel(-24, -24)
	            }, {
	                url: "//a.amap.com/jsapi_demos/static/images/darkRed.png",
	                size: new AMap.Size(48, 48),
	                offset: new AMap.Pixel(-24, -24)
	            }];
	            cluster = new AMap.MarkerCluster(map, points, {
	                styles: sts,
	                gridSize: gridSize 
	            });
	        } else {//默认样式
	            cluster = new AMap.MarkerCluster(map, points, {gridSize: gridSize});
	        }
	    }
}

function addText(map,element) {
    // 文本提示
    var text = new AMap.Text({
        text: element.num ? `${element.houseAddress} [${element.num}]` : `${element.name}` ,
        anchor:'center', // 设置文本标记锚点
        // draggable:true,
        cursor:'pointer',
        angle:0,
        style:{
            'padding': '.3rem .5rem',
            'margin-bottom': '1rem',
            'border-radius': '1rem',
            'background-color': element.num ? 'rgba(255,0,0,.5)' : 'rgba(40,70,140,.8)',
            'width': '5rem',
            'border-width': 0,
            'box-shadow': '0 2px 6px 0 rgba(114, 124, 245, .5)',
            'text-align': 'center',
            'font-size': '12px',
            'color': '#fff',
            'position': 'absolute',
            'top': '-55px',
            'left': '16px'
        },
        position: element.position
    })
    map.add(text)
}

// function option(val) {
//     console.log(val)
// }
// function communityInfos (element) {
//     let result = await communityInfo({
//         houseId: Number(element.houseId)
//     })
// }

function exMarker(map,element,icon,index) {
    let offset = new AMap.Pixel(-22.5, -53)
    var icon = new AMap.Icon({
        size: new AMap.Size(45, 53),
        image: icon,
        imageSize: new AMap.Size(45,53)
    });
    // var content = `<div class="content-icon" style="height:58px;width:45px;filter:url(#${index})"><img style="height:58px;width:45px" src="${icon}"></div>`
    var marker = new AMap.Marker({
        position: element.position,
        // content: content,
        // 将一张图片的地址设置为 icon
        icon: icon, //'//a.amap.com/jsapi_demos/static/demo-center/icons/dir-via-marker.png',
        // 设置了 icon 以后，设置 icon 的偏移量，以 icon 的 [center bottom] 为原点
        offset: offset,
    });
    map.add(marker)
    return marker
}

function showMarkerInfo(map, element, e, ani) {
    let contentInfo = '';
    if (ani) {
        //实例化信息窗体
        // let mes1 = "事故发生了";
        if (element.dataList && element.dataList.length) {
            let infoTitle =
                `<div class="map_top" style="  width: 18.75rem;
                    border-radius: 0.9375rem;
                    background: rgba(160, 32, 40, 0.7);">
                    <div class="top" style="padding: 0.9375rem;">`
            element.dataList.forEach(item => {
                infoTitle += `<p style=" margin-bottom: 0.3125rem;">` + item + `</p>`
            })
            infoTitle += `</div><div class="bot" style=" background: #d2221f;
                    padding: 0.9375rem;
                    border-bottom-left-radius: 0.9375rem;
                    border-bottom-right-radius: 0.9375rem;">` + element.addTime + `</div><div class="sj" style=" width: 0;
                    height: 0;
                    position: absolute;
                    left: 40%;
                    border-width: 1.275rem;
                    border-style: solid;
                    border-color: red transparent transparent transparent;"></div></div>`
            let content = [];
            contentInfo = createInfoWindow(map, infoTitle, content.join("<br/>"));
        }
    } else {
        let info = [];
        // info.push(`<div>区属：${element.id}</div>`)  
        element.dataList.forEach(item=>{
            info.push(`<div>${item}</div>`)
        })
        contentInfo = info.join("");
   
    }
    let infoWindow = new AMap.InfoWindow({
        offset: new AMap.Pixel(0, -30),
        anchor: "bottom-center",
        content: contentInfo,
        isCustom: ani
    });

    infoWindow.open(map, [e.lnglat.lng, e.lnglat.lat]);
}

function showDetailInfo(map, element, ani, lng, lat) {
    let contentInfo = '';
    if (ani) {
        let infoTitle =
            `<div class="map_top" style="  width: 18.75rem;
  								border-radius: 0.9375rem;
  								background: rgba(160, 32, 40, 0.7);">
  								<div class="top" style="padding: 0.9375rem;">`
            // <p style=" margin-bottom: 0.3125rem;">事件时间：2020-07-02</p>
            // <p style=" margin-bottom: 0.3125rem;">事件地点：xxx街道xxx路三段xx路口</p>
            // <p style=" margin-bottom: 0.3125rem;">事件详情：${title}发生险情</p>
            // <p style=" margin-bottom: 0.3125rem;">责任人：李XX</p>
            // <p style=" margin-bottom: 0.3125rem;">联系方式：13456789012</p>
        element.dataList.forEach(item => {
            infoTitle += `<p style=" margin-bottom: 0.3125rem;">` + item + `</p>`
        })
        infoTitle += `</div><div class="bot" style=" background: #d2221f;
  								padding: 0.9375rem;
  								border-bottom-left-radius: 0.9375rem;
  								border-bottom-right-radius: 0.9375rem;">` + element.addTime + `</div><div class="sj" style=" width: 0;
  								height: 0;
  								position: absolute;
  								left: 40%;
  								border-width: 1.275rem;
  								border-style: solid;
  								border-color: red transparent transparent transparent;"></div></div>`
        let content = [];
        contentInfo = createInfoWindow(map, infoTitle, content.join("<br/>"));
    } else {
        let info = [];
        if (element.name) {
            if(element.type ===1){
                info.push('<div style="padding:0px 4px 0px 4px; color: #fff;"><b>区属：' + element.name + "</b>");
                element.dataList.forEach(item => {

                    info.push(item);
                })
            }else{
                info.push('<div style="padding:0px 4px 0px 4px; color: #fff;"><b>区域：' + element.name + "</b>");
                element.dataList.forEach(item => {

                    info.push(item);
                })
            }
            
        }

        contentInfo = info.join("<br />");
    }
    let infoWindow = new AMap.InfoWindow({
        offset: new AMap.Pixel(0, -30),
        anchor: "bottom-center",
        content: contentInfo,
        isCustom: ani
    });

    infoWindow.open(map, [lng, lat]);
}

//构建自定义信息窗体
function createInfoWindow(map, title, content, type,id) {
    // let that = this;
    let status = null
    function closeInfoWindow() {
        map.clearInfoWindow();
    }
    // function exchange(val) {
    //     return new Promise((resolve,reject) => {
    //         resolve(val)
    //     })
    // } 
    function optionStatus1() {
        status = 2
        let boxs1 = document.getElementsByClassName('options1')
        let boxs2 = document.getElementsByClassName('options2')
        let boxs3 = document.getElementsByClassName('options3')
        boxs1[0].style.border = '1px solid rgb(15,135,255)'
        boxs2[0].style.border = 'none'
        boxs3[0].style.border = 'none'
    }
    function optionStatus2() {
        status = 0
        let boxs1 = document.getElementsByClassName('options1')
        let boxs2 = document.getElementsByClassName('options2')
        let boxs3 = document.getElementsByClassName('options3')
        boxs2[0].style.border = '1px solid rgb(15,135,255)'
        boxs1[0].style.border = 'none'
        boxs3[0].style.border = 'none'
    }
    function optionStatus3() {
        status = 1
        let boxs1 = document.getElementsByClassName('options1')
        let boxs2 = document.getElementsByClassName('options2')
        let boxs3 = document.getElementsByClassName('options3')
        boxs3[0].style.border = '1px solid rgb(15,135,255)'
        boxs2[0].style.border = 'none'
        boxs1[0].style.border = 'none'
    }
    function submit() {
        let remark = document.getElementsByClassName('warning-remark')[0].value
        submitStatus({
            id: id,
            status: status,
            remark: remark,
        }).then(data => {
            if(data.code == 0) {
                closeInfoWindow()
                store.dispatch('commom/setRemarkStatus', true)
            }
        })
    }
    var info = document.createElement("div");
    if(type == 'warning') {
        info.className = "custom-info warning input-card content-window-card";
    }else {
        info.className = "custom-info input-card content-window-card";
    }

    //可以通过下面的方式修改自定义窗体的宽高
    info.style.width = "400px";
    // 定义顶部标题
    var top = document.createElement("div");
    var titleD = document.createElement("div");
    var closeX = document.createElement("i");
    top.className = "info-top";
    if(type == 'warning') {
        titleD.className = "info-title active-remark"
    }else {
        titleD.className = "info-title"
    }
    
    titleD.innerHTML = title;
    // closeX.innerHTML = "x";
    closeX.style.position = "absolute";
    if(type == 'warning') {
        closeX.className = "el-icon-close active-remark";
    }else {
        closeX.className = "el-icon-close";
    }
    closeX.style.fontSize = "24px";
    // closeX.style.right = "2%";
    // closeX.style.top = "2%";
    closeX.onclick = closeInfoWindow;
    top.appendChild(titleD);
    top.appendChild(closeX);
    info.appendChild(top);
    // 内容区域
    var middle = document.createElement("div");
    middle.className = "info-middle";
    middle.innerHTML = content;
    info.appendChild(middle);
   
    // 底部
    if(id) {
        var bottom = document.createElement("div");
        bottom.className = "info-bottom";
        bottom.style.position = 'relative';
        bottom.style.top = '0px';
        bottom.style.margin = '0 auto';
        var option = document.createElement("div")
        option.className = "warning-option"
        var option1 = document.createElement("div")
        var option2 = document.createElement("div")
        var option3 = document.createElement("div")
        option1.className = 'options1'
        option2.className = 'options2'
        option3.className = 'options3'
        option1.innerHTML = '暂不处理'
        option2.innerHTML = '处理中'
        option3.innerHTML = '已处理'
        option1.onclick = optionStatus1
        option2.onclick = optionStatus2
        option3.onclick = optionStatus3
        option.appendChild(option1)
        option.appendChild(option2)
        option.appendChild(option3)
        var remark = document.createElement("div")
        remark.innerHTML = '处理说明：'
        remark.className = 'warning-info'
        var input = document.createElement("textarea")
        input.className = 'warning-remark'
        input.style.color = '#fff'
        var sub = document.createElement("div")
        sub.innerHTML = '完成'
        sub.className = 'warning-submit'
        sub.onclick = submit
        bottom.appendChild(option)
        bottom.appendChild(remark)
        bottom.appendChild(input)
        bottom.appendChild(sub)
        info.appendChild(bottom);
    }
    return info;
}

function addErea(map, points, color,info, callback) {
    var polygon = new AMap.Polygon({
        path: points,
        strokeColor: color, //'#FF0000',
        strokeWeight: 2,
        strokeOpacity: 1,
        fillOpacity: 0,
        fillColor: color, //'#FF0000',
        strokeStyle: 'dashed',
        zIndex: 50,
    });
    polygon.on('mouseover', (e) => {
        showDetailInfo(map,info,false,e.lnglat.getLng(),e.lnglat.getLat())
        callback(e);
    })
    polygon.on('mouseout',() => {
        map.clearInfoWindow();
    })
    map.add(polygon);
    var pathCenter = polygon.getBounds().getCenter()
    var textPath = pathCenter
    let textClick = new AMap.Text({
        text: info.name,
        anchor: "center", // 设置文本标记锚点
        style: {
            border: "none",
            "background-color": "transparent",
            "text-align": "center",
            "font-size": "10px",
            color: "#fff",
            "pointer-events": "none",
            },
            // position: bounds[i][6],
        position: textPath,
        clickable: true,
    })
    map.add(textClick)
    return polygon;
}
function addLine(map,path) {
    
    var polyline = new AMap.Polyline({
        path: path,
        isOutline: true,
        outlineColor: '#ffeeff',
        borderWeight: 0,
        strokeColor: "yellow",
        strokeOpacity: 1,
        strokeWeight: 1,
        // 折线样式还支持 'dashed'
        strokeStyle: "dashed",
        // strokeStyle是dashed时有效
        strokeDasharray: [5, 5],
        lineJoin: 'round',
        lineCap: 'round',
        zIndex: 50,
      })
      map.add(polyline);
    //   map.setFitView();
      return polyline
}
function address(longitude, latitude) {
    let arr = [longitude, latitude]
    var geocoder = new AMap.Geocoder({
        radius: 1000,
        extensions: "all"
    });
    return new Promise((resolve, reject) => geocoder.getAddress(arr, function (status, result) {
        if (result.info == 'OK') {
          resolve(result);
        } else {
          reject(result);
        }
    })) 
}

// 根据地址获得经纬度

function addressPosition(map,elment,address) {
	if (address.indexOf('简阳') < 0) {
		address = '简阳市'+address
	}
    var geocoder = new AMap.Geocoder({
        city: "简阳市", //城市设为北京，默认：“全国”
        radius: 1000,
        extensions: "all"
    });
    return new Promise((resolve, reject) => geocoder.getLocation(address, function(status, result) {
        if (status === 'complete'&&result.geocodes.length) {
            var lnglat = result.geocodes[0].location
            elment.position = lnglat
            resolve(elment) 
            // addMarker(map,elment)
        }else{
            console.log('根据地址查询位置失败');
        }
    })) 
    // geocoder.getLocation(address, function(status, result) {
    //     if (status === 'complete'&&result.geocodes.length) {
    //         var lnglat = result.geocodes[0].location
    //         elment.position = lnglat
    //         return elment
    //         addMarker(map,elment)
    //     }else{
    //         console.log('根据地址查询位置失败');
    //     }
    // });
}

function communityPosition(map,address) {
    var geocoder = new AMap.Geocoder({
        // city: "010", //城市设为北京，默认：“全国”
        // radius: 1000,
        // extensions: "all"
        city: "成都"
    });
    geocoder.getLocation(address, function(status, result) {
        if (status === 'complete'&&result.geocodes.length) {
            var lnglat = result.geocodes[0].location
            map.setCenter(lnglat)
            map.setZoom(18)
        }else{
            console.log('根据地址查询位置失败');
        }
    });
}

function clearWarningMarker(map) {
	map.remove(warningMarkerList)
}

// 地址解析
function parseAddress(addr) {
	return new Promise((resolve, reject) => {
		AMap.plugin('AMap.Geocoder', function() {
			var geocoder = new AMap.Geocoder({
				city: '028' // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
			})
			
		
			geocoder.getLocation(addr, function(status, result) {
				if (status === 'complete' && result.info === 'OK') {
					// result中对应详细地理坐标信息
					resolve(result.geocodes[0].location)
				}
			})
		})		
	})

}

export {
    mapLoader,
    initMap,
    initMap3D,
    addMarker,
    addText,
    exMarker,
    addErea,
    showDetailInfo,
    search,
    addLine,
    address,
    addressPosition,
    communityPosition,
		addMarkerCluster,
		clearWarningMarker,
		parseAddress
}